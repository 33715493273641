import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import Datetime from 'react-datetime';
import get from 'lodash/get';
import { withFormik } from 'formik';
import { Button, Box, HStack, Separator, VStack, Text, IconButton, Grid } from '@chakra-ui/react';
import { FaSearch } from 'react-icons/fa';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { random } from 'lodash';

import Constants from '../../Constants';
import SelectField from '../basic/SelectField';
import { isUSRegionGrinder } from './helpers';
import { isManualPriceStrategyByEndUser } from '../../utils';
import { filterGrinderOptionsByEndUser } from '../orders/order-form/CreatePurchaseOrderForm/helpers';
import CustomFormControl from '../core/CustomFormControl';
import { Tooltip } from '@/components/ui/tooltip';
import { Radio, RadioGroup } from '@/components/ui/radio';

const { INVOICE_FLOWS } = Constants;

const INVOICE_DATE_TYPE_OPTIONS = [
  { label: 'etd', value: 'etd' },
  { label: 'eta', value: 'eta' },
  { label: 'delivery date', value: 'delivery_date' },
];

const INVOICE_OFFICE_OPTIONS = [Constants.BUSINESS_OFFICES.SYDNEY, Constants.BUSINESS_OFFICES.CHICAGO];

const FINANCE_CART_DEFAULT_OPTIONS = [
  { label: 'Lock Prices', value: 'priceCart' },
  { label: 'Invoice', value: 'invoiceCart' },
];

const FINANCE_CART_MANUAL_OPTIONS = [{ label: 'Invoice', value: 'invoiceCart' }];

const PRIOR_FINAL_OPTIONS = [
  { label: 'Prior', value: 'prior' },
  { label: 'Final', value: 'final' },
];

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    grinder: Yup.string().required('Required'),
    endUserId: Yup.number().required('Required'),
    dateType: Yup.string(),
    dateStart: Yup.string(),
    dateEnd: Yup.string(),
    invoiceType: Yup.string(),
    searchFlow: Yup.string(),
    invoiceOffice: Yup.string(),
  }),
  handleSubmit: (values, form) => {
    form.props.onSubmit(values);
    form.setSubmitting(false);
  },
});

class InvoiceFilters extends Component {
  static propTypes = {
    grinderOptions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        invoice_date_type: PropTypes.string,
        invoice_flow: PropTypes.string,
        uid: PropTypes.string,
      })
    ),
    // onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    // Populated by Formik:
    handleSubmit: PropTypes.func,
    isSubmitting: PropTypes.bool,
    values: PropTypes.instanceOf(Object),
    setFieldValue: PropTypes.func,
    pricingStrategy: PropTypes.bool,
    endUserOptions: PropTypes.arrayOf(Object),
    endUsers: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        grinders_uids: PropTypes.arrayOf(PropTypes.string),
      })
    ),
    defaultEndUserName: PropTypes.string,
  };

  handleChangeGrinder = grinder => {
    const { values, setFieldValue } = this.props;
    const dateType = get(grinder, 'invoice_date_type', null);
    const invoiceFlow = get(grinder, 'invoice_flow');
    setFieldValue('dateType', dateType);
    setFieldValue('grinder', grinder ? grinder.value : null);
    setFieldValue('invoiceOffice', INVOICE_OFFICE_OPTIONS[0].value);
    if (values.pricingStrategy) {
      setFieldValue('searchFlow', FINANCE_CART_MANUAL_OPTIONS[0].value);
    } else {
      if (invoiceFlow === INVOICE_FLOWS.PRIOR_FINAL && !values.invoiceType) {
        setFieldValue('invoiceType', PRIOR_FINAL_OPTIONS[0].value);
      }
      if (isUSRegionGrinder(grinder)) {
        setFieldValue('searchFlow', FINANCE_CART_DEFAULT_OPTIONS[0].value);
      }
    }
  };

  handleChangeEndUser = endUser => {
    const { setFieldValue, pricingStrategy } = this.props;
    setFieldValue('endUserId', endUser?.value);
    setFieldValue('invoiceOffice', INVOICE_OFFICE_OPTIONS[0].value);
    this.handlePricingStrategy(endUser?.value, pricingStrategy);
  };

  handlePricingStrategy = (endUserId, configValue) => {
    const { setFieldValue, endUsers } = this.props;
    if (configValue === Constants.PRICING_STRATEGY.COMBINE) {
      setFieldValue('pricingStrategy', isManualPriceStrategyByEndUser(endUsers, endUserId));
    }
    if (configValue === Constants.PRICING_STRATEGY.DEFAULT) {
      setFieldValue('pricingStrategy', false);
    }
    if (configValue === Constants.PRICING_STRATEGY.MANUAL) {
      setFieldValue('pricingStrategy', true);
    }
  };

  render() {
    const {
      grinderOptions,
      values,
      onCancel,
      handleSubmit,
      setFieldValue,
      isSubmitting,
      endUserOptions,
      defaultEndUserName,
    } = this.props;
    const selectedGrinder = grinderOptions.find(grinder => grinder.value === values.grinder);
    const invoiceFlow = get(selectedGrinder, 'invoice_flow');
    const isUSAGrinder = isUSRegionGrinder(selectedGrinder);
    const endUser = endUserOptions.filter(user => user.value === values.endUserId);

    let filteredGrindersOptions = null;
    if (endUser.length === 1) {
      filteredGrindersOptions = filterGrinderOptionsByEndUser(endUser[0], grinderOptions);
    }
    let filteredEndUserOptions = null;
    if (selectedGrinder) {
      filteredEndUserOptions = endUserOptions.filter(user => user.grinders_uids.includes(selectedGrinder.uid));
    }
    const defaultEndUser = endUserOptions.filter(user => user.label === defaultEndUserName);

    if (
      !values.endUserId &&
      defaultEndUser.length === 1 &&
      typeof defaultEndUser[0] === 'object' &&
      values.endUserId !== defaultEndUser[0].value
    ) {
      this.handleChangeEndUser(defaultEndUser[0]);
    }
    let endUserValue = null;
    if (values.endUserId) {
      endUserValue = endUserOptions.filter(user => user.value === values.endUserId);
    }

    let invoicePriceOptions = [];
    if (values.pricingStrategy === true) {
      invoicePriceOptions = FINANCE_CART_MANUAL_OPTIONS;
    } else {
      invoicePriceOptions = FINANCE_CART_DEFAULT_OPTIONS;
    }

    return (
      <Box>
        <form onSubmit={handleSubmit}>
          <Grid
            templateColumns="repeat(4, 1fr)"
            gap="45px"
            separator={<Separator borderColor="gray.400" />}
            alignItems="flex-start"
          >
            {/* <!-- LEFT COLUMN --> */}
            <VStack align="stretch" gap="16px">
              {/* <!-- END USER SELECT --> */}
              <CustomFormControl>
                <SelectField
                  options={filteredEndUserOptions || endUserOptions}
                  key="select-field-end-user"
                  label={
                    <>
                      End User select&nbsp;
                      <Tooltip
                        content="Select the end user to filter purchase orders for invoicing."
                        positioning={{ placement: 'right-end' }}
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                    </>
                  }
                  placeholder="End User"
                  name="endUser"
                  isMulti={false}
                  closeMenuOnSelect
                  isClearable
                  value={endUserValue}
                  onChange={this.handleChangeEndUser}
                />
              </CustomFormControl>
              {/* <!-- GRINDER SELECT --> */}
              <CustomFormControl>
                <SelectField
                  options={filteredGrindersOptions?.length > 0 ? filteredGrindersOptions : grinderOptions}
                  key="select-field-grinder"
                  label={
                    <>
                      Grinder select&nbsp;
                      <Tooltip
                        content="Select the Grinder to filter purchase orders for adding prices and invoices."
                        positioning={{ placement: 'right-end' }}
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                    </>
                  }
                  placeholder="Select Grinder"
                  name="grinder"
                  isMulti={false}
                  closeMenuOnSelect
                  isClearable
                  onChange={this.handleChangeGrinder}
                />
              </CustomFormControl>
              {/* <!-- INVOICING OFFICE --> */}
              <div style={{ display: 'none' }}>
                <CustomFormControl>
                  <SelectField
                    options={INVOICE_OFFICE_OPTIONS}
                    key="select-invoice-office"
                    label="Invoicing Office select"
                    placeholder="Invoicing Office"
                    name="invoiceOffice"
                    isMulti={false}
                    closeMenuOnSelect
                    isClearable
                    value={INVOICE_OFFICE_OPTIONS.find(io => io.value === values.invoiceOffice)}
                    onChange={invoiceOffice => {
                      setFieldValue('invoiceOffice', invoiceOffice.value);
                      if (invoiceOffice.value === Constants.BUSINESS_OFFICES.CHICAGO.value) {
                        setFieldValue('searchFlow', FINANCE_CART_MANUAL_OPTIONS[0].value);
                      }
                    }}
                  />
                </CustomFormControl>
              </div>
              {/* <!-- PRIOR/FINAL FLOW --> */}
              {invoiceFlow === INVOICE_FLOWS.PRIOR_FINAL &&
                values.invoiceOffice === Constants.BUSINESS_OFFICES.SYDNEY.value && (
                  <CustomFormControl label="Choose invoicing flow:">
                    <RadioGroup
                      colorScheme="actionPrimary"
                      value={values.invoiceType}
                      onValueChange={e => setFieldValue('invoiceType', e.value)}
                    >
                      <VStack align="stretch" gap="20px">
                        {PRIOR_FINAL_OPTIONS.map(option => (
                          <Radio key={`radio-${option.value}-${random()}`} marginBottom="0" value={option.value}>
                            <Text as="p" fontWeight={option.value === values.invoiceType && 'medium'}>
                              {option.label}
                            </Text>
                          </Radio>
                        ))}
                      </VStack>
                    </RadioGroup>
                  </CustomFormControl>
                )}
                
            </VStack>
            
            <VStack align="stretch" gap="16px" borderLeft="1px solid #e4e4e7" paddingLeft="40px;">
              {(values.pricingStrategy || isUSAGrinder) &&
                values.invoiceOffice === Constants.BUSINESS_OFFICES.SYDNEY.value && (
                  <CustomFormControl
                    label={
                      <>
                        Choose action&nbsp;
                        <Tooltip
                          content="Filter purchase orders by Locked Price or Invoice."
                          positioning={{ placement: 'right-end' }}
                        >
                          <IconButton
                            width="14px"
                            height="14px"
                            padding="0"
                            minW="auto"
                            borderRadius="50%"
                            color="#878787"
                            variant="unstyled"
                          >
                            <IoInformationCircleOutline size="14px" />
                          </IconButton>
                        </Tooltip>
                        :
                      </>
                    }
                  >
                    <RadioGroup
                      colorScheme="actionPrimary"
                      value={values.searchFlow}
                      onValueChange={e => setFieldValue('searchFlow', e.value)}
                    >
                      <VStack align="stretch" gap="20px">
                        {invoicePriceOptions.map(option => (
                          <Radio key={`radio-${option.value}-${random()}`} value={option.value} marginBottom="0">
                            <Text as="p" fontWeight={option.value === values.searchFlow && 'medium'}>
                              {option.label}
                            </Text>
                          </Radio>
                        ))}
                      </VStack>
                    </RadioGroup>
                  </CustomFormControl>
                )}
              {/* <!-- DATE TYPE --> */}
              <CustomFormControl
                label={
                  <>
                    Select orders by&nbsp;
                    <Tooltip
                      content="Filter purchase orders based on ETD, ETA, or Delivery Date."
                      positioning={{ placement: 'right-end' }}
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        variant="unstyled"
                      >
                        <IoInformationCircleOutline size="14px" />
                      </IconButton>
                    </Tooltip>
                    :
                  </>
                }
              >
                <RadioGroup
                  colorScheme="actionPrimary"
                  value={values.dateType}
                  onValueChange={e => setFieldValue('dateType', e.value)}
                >
                  <VStack align="stretch" gap="20px">
                    {INVOICE_DATE_TYPE_OPTIONS.map(option => {
                      return (
                        <Radio
                          key={`radio-${option.label}-${random()}`}
                          value={option.value}
                          // checked={option.value === values.dateType}
                          marginBottom="0"
                        >
                          <Text as="p" fontWeight={option.value === values.dateType && 'medium'}>
                            {option.label}
                          </Text>
                        </Radio>
                      );
                    })}
                  </VStack>
                </RadioGroup>
                <Button
                  variant="ghost"
                  mt="18px"
                  colorScheme="actionPrimary"
                  fontSize="16px"
                  onClick={() => {
                    setFieldValue('dateType', '');
                    setFieldValue('dateStart', '');
                    setFieldValue('dateEnd', '');
                    setFieldValue('searchFlow', '');
                    onCancel();
                  }}
                >
                  Clear
                </Button>
              </CustomFormControl>
            </VStack>
            {/* <!-- DATE RANGE --> */}
            {values.dateType && values.endUserId && (
              <VStack align="stretch" gap="16px" height="100%" borderLeft="1px solid #e4e4e7" paddingLeft="40px;">
                <label>{values.dateType.replace(/_/g, ' ')}</label>
                <HStack gap="10px">
                  <CustomFormControl width="150px">
                    <Datetime
                      id="dateStart"
                      name="dateStart"
                      inputProps={{
                        placeholder: 'Select',
                        className: 'form-control m-input',
                      }}
                      closeOnSelect
                      value={values.dateStart}
                      onChange={input =>
                        setFieldValue('dateStart', get(input, 'format') ? input.format('YYYY-MM-DD') : input)
                      }
                      timeFormat={false}
                      dateFormat="YYYY-MM-DD"
                    />
                  </CustomFormControl>
                  <Text as="p" fontWeight="medium">
                    to
                  </Text>
                  <CustomFormControl width="150px">
                    <Datetime
                      id="dateEnd"
                      name="dateEnd"
                      inputProps={{
                        placeholder: 'Select',
                        className: 'form-control m-input',
                      }}
                      closeOnSelect
                      value={values.dateEnd}
                      onChange={input =>
                        setFieldValue('dateEnd', get(input, 'format') ? input.format('YYYY-MM-DD') : input)
                      }
                      timeFormat={false}
                      dateFormat="YYYY-MM-DD"
                    />
                  </CustomFormControl>
                  <IconButton colorScheme="actionSecondary" disabled={isSubmitting} onClick={handleSubmit}>
                    <FaSearch />
                  </IconButton>
                </HStack>
              </VStack>
            )}
          </Grid>
        </form>
      </Box>
    );
  }
}

export default formikEnhancer(InvoiceFilters);
